class IdleTimer {
	constructor({ timeout, onTimeout }) {
		this.timeout = timeout;
		this.onTimeout = onTimeout;
		this.interval = null;

		this.startInterval();
	}

	startInterval() {
		this.interval = setInterval(() => {
			this.onTimeout();
		}, this.timeout * 1000);
	}

	async cleanUp() {
		await clearInterval(this.interval);
		return null;
	}
}

export default IdleTimer;
